import { css } from "lit-element";

export const homeStyles = css`
    .width-holder {
        max-width: 1800px;
        padding: 0 20px;
        margin: 0 auto;
    }
`;

//touched for CI under pressure, delete me
